<template lang='pug'>
.w-100.df-col-ac.bg-white.p-5.h-100
  .vote
  .mt-3.pf-title.text-primary.text-center
    div Thank you for your
    div submission!
</template>

<script>
import { Button, Popup } from 'vant'

export default {
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    handleResult() {
      this.$router.push({
        path: '/result'
      })
    }
  }
}
</script>

<style lang="scss">
</style>
